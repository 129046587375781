import { graphql } from 'gatsby'
import { EditorProvider } from '@tiptap/react'
import { Header, Footer } from '@/components'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import { Box } from '@mui/material'
import React from 'react'
import './style.css'

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        parseHTML: element => element.getAttribute('data-background-color'),
        renderHTML: attributes => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
      },
      textAlign: {
        default: null,
        parseHTML: element => element.getAttribute('text-align'),
        renderHTML: attributes => {
          return {
            'text-align': attributes.textAlign,
            style: `text-align: ${attributes.textAlign}`,
          }
        },
      },
    }
  },
})

const extensions = [
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Link.configure({
    HTMLAttributes: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  }),
  Table,
  TableRow,
  TableHeader,
  CustomTableCell,
]

const content = `
<h2 style="text-align:center">
  PRIVACY POLICY
</h2>
<p/>
<p>
  <strong>IMILE DELIVERY BRAZIL LTDA.</strong>, a private legal entity, registered under CNPJ 47.173.294/0001-17, located at Alameda Rio Negro, <br/>
  No. 585, suite 51, 5th floor, Alphaville, Barueri/SP, ZIP Code: 06470-040 ("iMile"), processes some of your personal data to <br/>
  provide services. Currently, we are a logistics company headquartered in Dubai operating in Brazil, and we want to explain how <br/>
  we use your data on our websites and during the execution of our services (<a href="https://www.imile.com/pt">https://www.imile.com/pt</a>).
</p>
<p>
  This <strong>Privacy Policy</strong> aims to show you how <strong>iMile</strong> handles the personal data to which it has access, what your rights are as the data<br/> 
  subject, and how you can exercise them. If you have any questions, please contact us through the channels indicated at the end<br/> 
  of this Policy.
</p>
<h3>
  WHAT ARE PERSONAL DATA?
</h3>
<p>
  Personal data are pieces of information that identify a natural person or that can identify them. This includes elements such as <br/>
  name, identification number, location data, online identifier, and characteristics that allow direct or indirect identification of the <br/>
  person.
</p>
<h3>
  WHY DO WE USE YOUR PERSONAL DATA?
</h3>
<p>
  We use some of your personal data necessary for the provision of our services, such as name, address, and phone number. <br />
  Additionally, we use essential information for shipment delivery, such as delivery status, which includes data like address, legal<br/> 
  documents required for shipment transport, and order and/or shipment number.
</p>
<p></p>
<table>
  <tbody>
    <tr>
      <th>Types of Personal Data</th>
      <th>Purpose</th>
    </tr>
    <tr>
      <td>Name, order number, address and telephone number</td>
      <td>Delivery of shipments (products purchased from our clients' websites <br/>
          and/or physical stores).
      </td>
    </tr>
    <tr>
      <td>Name, order number and address</td>
      <td>Issue tax documents for shipment transport.</td>
    </tr>
  </tbody>
</table>
<p></p>
<h3>
  FULFILLMENT OF A CONTRACT
</h3>
<p>
  We use your personal data based on pre-contractual and contractual reasons to provide our services and fulfill the obligations <br />
  established in the contractual agreements entered into with you.
</p>
<h3>
  COOKIES
</h3>
<p>
  Data that you provide while browsing the Site and that are collected through cookies, please refer to our Cookie Policy.
</p>
<h3>
  WHO WE SHARE YOUR PERSONAL DATA WITH
</h3>
<p>
  <strong>iMile</strong> collaborates with other companies to offer its services. Therefore, we may share your personal data with these companies <br />
  while ensuring the utmost respect for your privacy and, whenever possible, in anonymized form. Below, we describe situations <br/>
  in which we may share personal data:
</p>
<ul>
  <li>
    <strong>iMile Brands: </strong>Your information may be shared among <strong>iMile</strong> companies and brands. We do this for (i) servicing our and <br/>
  your interests for the execution and improvement of our services; (ii) data analysis; among others.
  </li>
  <li>
    <strong>Suppliers and Partners: </strong> We rely on the assistance of suppliers and partners who may process personal data that we <br/>
    receive and collect. Information is shared only for the purposes of the services provided. We always carefully assess <br/>
    our suppliers and establish contractual obligations with them regarding the protection of personal data and <br/>
    information security, aiming to minimize risks to data subjects. Among these suppliers are, for example: (i) <br/>
    technological platforms or systems; (ii) logistics companies for contract fulfillment and order delivery; (iii) customer <br/>
    service companies; (iv) companies specialized in fraud prevention; among others.
  </li>
  <li>
    <strong>Other Countries: iMile </strong> has a branch in Brazil and a headquarters in Dubai, and the Site is intended for people located <br/>
    in Brazil, thus Brazilian laws related to personal data protection apply. However, personal data that we collect may be <br/>
    transferred to companies located in other countries, such as the United Arab Emirates, China.
  </li>
</ul>
<p>
  We reserve the right to share any personal data that we believe is necessary to fulfill a legal obligation or to protect our rights <br/>
  and those of our employees. Additionally, we may be required to share some of your personal data with public entities to comply <br/>
  with legal or regulatory obligations or to comply with orders from competent authorities.
</p>
<p>
  If you have any questions regarding the companies with which we share data, please contact the Data Protection Officer (DPO) <br/>
  using the contact channels provided below.
</p>
<h3>
  DATA SUBJECT RIGHTS
</h3>
<p>
  You have certain rights regarding the data we process. Below is a list of these rights, along with information on how you can<br/>
  exercise them. Upon receiving your request, we may ask for additional information to verify your identity and review your <br/>
  request. To exercise any of these rights, you can contact us via email using the channels provided at the end of this Policy.
</p>
<p></p>
<table>
  <tbody>
    <tr>
      <td data-background-color="rgba(61, 37, 20, .05)" text-align="center">Confirmation and Access</td>
      <td>Allows you to verify if we process your personal data and, if so, request a copy of the <br/>
          personal data we hold about you.</td>
    </tr>
    <tr>
      <td data-background-color="rgba(61, 37, 20, .05)" text-align="center">Correction</td>
      <td>Allows you to request the correction of your incomplete, inaccurate, or outdated <br/>
          personal data.</td>
    </tr>
    <tr>
      <td data-background-color="rgba(61, 37, 20, .05)" text-align="center">Anonymization, blocking ou deletion</td>
      <td>Allows you to request: (a) anonymization of your data, so that it can no longer be related <br/>
          to you and ceases to be personal data; (b) blocking of your data, temporarily suspending <br/>
          its processing; and (c) deletion of your data, in which case we will erase all your data <br/>
          with no possibility of recovery, except as required by law.</td>
    </tr>
    <tr>
      <td data-background-color="rgba(61, 37, 20, .05)" text-align="center">Portability</td>
      <td>You have the right to request that your personal data be transferred to another <br/>
          company in a structured, commonly used, and machine-readable format, provided that <br/>
          this does not violate intellectual property rights or trade secrets of the company.</td>
    </tr>
    <tr>
      <td data-background-color="rgba(61, 37, 20, .05)" text-align="center">Information on Sharing</td>
      <td>You have the right to know the public and private entities with which we share your <br/>
          personal data. If you have questions or want more details, you have the right to request <br/>
          this information from us.</td>
    </tr>
    <tr>
      <td data-background-color="rgba(61, 37, 20, .05)" text-align="center">Information on the Possibility of Not <br/>
          Consenting</td>
      <td>You have the right to receive clear and complete information about the consequences <br/>
          of not providing consent. Your consent, when necessary, must be freely given and <br/>
          informed. Therefore, you always have the right to refuse consent, although this may  <br/>
          result in limitations to some services.
          </td>
    </tr>
    <tr>
      <td data-background-color="rgba(61, 37, 20, .05)" text-align="center">Withdrawal of Consent</td>
      <td>You have the right to withdraw your consent for the processing of data based on <br/>
          consent. This will not affect the lawfulness of processing based on consent before its <br/>
          withdrawal. However, we may not be able to provide certain communications or <br/>
          services if you withdraw your consent.</td>
    </tr>
    <tr>
      <td data-background-color="rgba(61, 37, 20, .05)" text-align="center">Objection</td>
      <td>The law allows the processing of personal data even without your consent. If you <br/>
          disagree with such processing, in some cases, you may object to it by requesting its <br/>
          cessation.
          </td>
    </tr>
  </tbody>
</table>
<p></p>
<h3>
  HOW LONG WILL PERSONAL DATA BE STORED
</h3>
<p>
  Personal data is stored for as long as necessary to fulfill its specific collection purposes, unless required by legal, regulatory, <br/>
  contractual, or other justifiable bases. Users have the right to request the deletion of their data.
</p>
<h3>
  OUR RESPONSIBILITIES AND HOW WE PROTECT YOUR PERSONAL DATA
</h3>
<p>
  Our responsibility is to care for your personal data and use it for lawful purposes as described in this Policy. To ensure your <br/>
  privacy and protect your personal data, we adopt appropriate security practices for our market, utilizing techniques and other <br/>
  information security systems.
</p>
<p>
  <strong>We work to preserve your privacy and protect your personal data, but unfortunately, we cannot guarantee complete security. </strong><br/>Unauthorized access or use by third parties in your account, hardware or software failure beyond <strong>iMile's</strong> control, and other <br/>
  factors may compromise the security of your personal data. Therefore, your cooperation is essential to maintaining a secure <br/>
  environment for everyone. You can help us by adopting good security practices regarding your account and data. If you become <br/>
  aware of anything compromising the security of your data, please contact us through our Data Protection Officer, whose contact <br/>
  details are provided below.
</p>
<h3>
  GENERAL INFORMATION
</h3>
<p>
  If you have questions, comments, or suggestions regarding this Policy, please contact us via the following email: <a href="mailto:dpobrazil@imile.me">dpo@imile.me</a>
</p>
<p>
  <strong>Version Record: </strong>1st Version on July 15, 2024.
</p>
<p style="text-align:center"><strong>* * *</strong></p>
`

const PrivacyPolicyBrazil = (props) => {
  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <Box pt={15} display='flex' alignItems='center' justifyContent='center'>
        <EditorProvider extensions={extensions} content={content} editable={false}></EditorProvider>
      </Box>
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export default PrivacyPolicyBrazil

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["PrivacyPolicyBrazil"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
